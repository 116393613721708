import React from 'react'

const WalletBoxes = () => {
  return (
    <div className="container text-center">
      <div className="cs-height_45 cs-height_lg_45" />
      <div className="row">
        <div className="col-lg-6 col-sm-6">
          <div
            className="cs-iconbox cs-style3 cs-box_shadow py-5"
            style={{ background: "cover", backgroundImage: "url(../assets/img/banner1.jpg)" }}
          >
            <div className="cs-iconbox_img">
              <h2>Biggest shop for streamers</h2>
            </div>
            <div className="cs-iconbox_text">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
              sint. Velit officia consequat duis enim velit mollit.
            </div>
            <a href="#" className="cs-iconbox_btn cs-primary_font">
              Connect Wallet
              <svg
                width={17}
                height={12}
                viewBox="0 0 17 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30" />
        </div>
        <div className="col-lg-6 col-sm-6">
          <div
            className="cs-iconbox cs-style3 cs-box_shadow py-5"
            style={{ background: "cover", backgroundImage: "url(../assets/img/banner2.jpg)" }}
          >
            <div className="cs-iconbox_img">
              <h2>Best subscription for streamers</h2>
            </div>
            <div className="cs-iconbox_text">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
              sint. Velit officia consequat duis enim velit mollit.
            </div>
            <a href="#" className="cs-iconbox_btn cs-primary_font">
              Connect Wallet
              <svg
                width={17}
                height={12}
                viewBox="0 0 17 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30" />
        </div>
        <div className="col-lg-6 col-sm-6">
          <div
            className="cs-iconbox cs-style3 cs-box_shadow  py-5"
            style={{ background: "cover", backgroundImage: "url(../assets/img/banner3.jpg)" }}
          >
            <div className="cs-iconbox_img">
              <h2>Streaming Tool</h2>
            </div>
            <div className="cs-iconbox_text">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
              sint. Velit officia consequat duis enim velit mollit.
            </div>
            <a href="#" className="cs-iconbox_btn cs-primary_font">
              Connect Wallet
              <svg
                width={17}
                height={12}
                viewBox="0 0 17 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30" />
        </div>
        <div className="col-lg-6 col-sm-6">
          <div
            className="cs-iconbox cs-style3 cs-box_shadow py-5"
            style={{ background: "cover", backgroundImage: "url(../assets/img/banner4.jpg)" }}
          >
            <div className="cs-iconbox_img">
              <h2>Emote Maker</h2>
            </div>
            <div className="cs-iconbox_text">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
              sint. Velit officia consequat duis enim velit mollit.
            </div>
            <a href="#" className="cs-iconbox_btn cs-primary_font">
              Connect Wallet
              <svg
                width={17}
                height={12}
                viewBox="0 0 17 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30" />
        </div>
      </div>
    </div>

  )
}

export default WalletBoxes