import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/plugins/bootstrap.min.css';
import './assets/css/plugins/fontawesome.min.css';
import './assets/css/plugins/slick.css';
import './assets/css/style.css';
import { MainContextProvider } from './context/MainContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MainContextProvider>
    <App />
  </MainContextProvider>
);
