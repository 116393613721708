import React from 'react'
import Slider from '../components/Slider'
import WalletBoxes from '../components/WalletBoxes'
import EmotesSliderCards from '../components/EmotesSliderCards'
import TopStreamerEmotes from '../components/TopStreamerEmotes'
import Competitive from '../components/Competitive'
import Blog from '../components/Blog'

const Home = () => {
    return (
        <>
            <div className="cs-height_30 cs-height_lg_30" />
            <Slider />
            <WalletBoxes />
            <div className="cs-height_95 cs-height_lg_70"></div>
            <EmotesSliderCards title="Stream Design Bestseller" />
            <div className="cs-height_70 cs-height_lg_40"></div>
            <EmotesSliderCards title="Subscriber Emotes" />
            <div className="cs-height_70 cs-height_lg_40"></div>
            <EmotesSliderCards title="Stream Alerts" />
            <div className="cs-height_100 cs-height_lg_70"></div>
            <TopStreamerEmotes />
            <div className="cs-height_100 cs-height_lg_70"></div>
            <div className="cs-height_95 cs-height_lg_70"></div>
            <Competitive />
            <div className="cs-height_70 cs-height_lg_40"></div>

            <>
                {/* Start CTA */}
                <section>
                    <div className="container">
                        <div
                            className="cs-cta cs-style1 cs-bg"
                            data-src="assets/img/general/cta_bg.jpeg"
                            style={{ backgroundImage: 'url(assets/img/general/cta_bg.jpeg)' }}
                        >
                            <div className="cs-cta_img">
                                <img src="assets/img/general/cta.svg" alt="Image" />
                            </div>
                            <div className="cs-cta_right">
                                <h2 className="cs-cta_title cs-white_color_8">
                                    Create, Sell well &amp; Collect your Best NFTs with us Very Fast
                                </h2>
                                <div className="cs-cta_subtitle cs-white_color_8">
                                    What’s Different Lorem Ipsum is simply dummy text of the printing
                                    and typesetting industry. Lorem Ipsum has Lorsum is simply dummy
                                    text of the printing and{" "}
                                </div>
                                <a href="#" className="cs-btn cs-style1 cs-btn_lg">
                                    <span>Connect Wallet</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End CTA */}
            </>

            <div className="cs-height_95 cs-height_lg_70"></div>
            <Blog /></>
    )
}

export default Home