

import BadgeHeader from '../components/BadgeHeader'

const Register = () => {
    return (
        <>
            <div className="cs-height_90 cs-height_lg_80" />
            {/* Start Page Head */}
            <BadgeHeader title='Sign Up' />
            {/* End Page Head */}

            <div className="cs-height_100 cs-height_lg_70" />
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-8 offset-xl-3 offset-md-2">
                        <form className="cs-form_card cs-style1 cs-box_shadow cs-white_bg">
                            <div className="cs-form_card_in">
                                <h2 className="cs-form_title text-center">Create Account</h2>
                                <div className="cs-form_btns">
                                    <a href="#" className="cs-btn cs-style2 cs-btn_lg">
                                        <span>
                                            <i className="fab fa-google" />
                                            Google
                                        </span>
                                    </a>
                                    <a href="#" className="cs-btn cs-style2 cs-btn_lg">
                                        <span>
                                            <i className="fab fa-facebook-f" />
                                            Facebook
                                        </span>
                                    </a>

                                </div>
                                <div className="cs-height_30 cs-height_lg_30" />
                                <div className="cs-form_field_wrap">
                                    <input
                                        type="text"
                                        className="cs-form_field"
                                        placeholder="Username"
                                    />
                                </div>
                                <div className="cs-height_20 cs-height_lg_20" />
                                <div className="cs-form_field_wrap">
                                    <input
                                        type="email"
                                        className="cs-form_field"
                                        placeholder="Your Email"
                                    />
                                </div>
                                <div className="cs-height_20 cs-height_lg_20" />
                                <div className="cs-form_field_wrap">
                                    <input
                                        type="password"
                                        className="cs-form_field"
                                        placeholder="Set your password"
                                    />
                                </div>
                                <div className="cs-height_20 cs-height_lg_20" />
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="notify" />
                                    <label className="form-check-label" htmlFor="notify">
                                        I'm attracted in receiving marketing emails for updates on
                                        upcoming promotions, events and new features
                                    </label>
                                </div>
                                <div className="cs-height_10 cs-height_lg_10" />
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="terms" />
                                    <label className="form-check-label" htmlFor="terms">
                                        I'm attracted in receiving marketing emails for updates on
                                        upcoming promotions, events and new features
                                    </label>
                                </div>
                                <div className="cs-height_20 cs-height_lg_20" />
                                <button className="cs-btn cs-style1 cs-btn_lg w-100">
                                    <span>Register Now</span>
                                </button>
                                <div className="cs-height_30 cs-height_lg_30" />
                                <div className="cs-form_footer text-center">
                                    Have an account? <a href="#">Log In</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="cs-height_100 cs-height_lg_70" />

        </>

    )
}

export default Register