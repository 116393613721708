import React from 'react'
import { Link } from 'react-router-dom'

const BadgeHeader = ({ title }: { title: string }) => {
    return (
        <section
            className="cs-page_head cs-bg"
            data-src="assets/img/page_head_bg.svg"
            style={{backgroundImage:"url(assets/img/page_head_bg.svg)"}}
        >
            <div className="container">
                <div className="text-center">
                    <h1 className="cs-page_title">{title}</h1>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">{title}</li>
                    </ol>
                </div>
            </div>
        </section>
    )
}

export default BadgeHeader