import React from 'react'

const Blog = () => {
    return (
        <>
            {/* Start Blog Section */}
            <section>
                <div className="container">
                    <div className="cs-section_heading cs-style2">
                        <div className="cs-section_left">
                            <h2 className="cs-section_title">Our Blogs</h2>
                        </div>
                        <div className="cs-section_right">
                            <a href="#" className="cs-btn cs-style1">
                                <span>Explore More</span>
                            </a>
                        </div>
                    </div>
                    <div className="cs-height_45 cs-height_lg_45" />
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="cs-post cs-style1">
                                <a href="blog-details.html" className="cs-post_thumb">
                                    <div
                                        className="cs-post_thumb_in cs-bg"
                                        data-src="assets/img/general/general_21.jpeg"
                                        style={{backgroundImage:'url(assets/img/general/general_21.jpeg)'}}
                                    />
                                </a>
                                <div className="cs-post_info">
                                    <h2 className="cs-post_title">
                                        <a href="blog-details.html">
                                            Guide to Making, Buying and Selling NFT
                                        </a>
                                    </h2>
                                    <div className="cs-post_subtitle">
                                        It is a long established fact that a reader will be distrac by
                                        the readable content of a page...
                                    </div>
                                    <div className="cs-height_20 cs-height_lg_20" />
                                    <div className="cs-post_avatar">
                                        <a href="#" className="cs-post_avatar_img">
                                            <img src="assets/img/avatar/avatar_18.png" alt="Avatr" />
                                        </a>
                                        <div className="cs-post_avatar_right">
                                            <h2 className="cs-post_avatar_name cs-semi_bold">
                                                <a href="#">Marina G. Trujillo</a>
                                            </h2>
                                            <div className="cs-post_meta">
                                                <span className="cs-post_meta_item">5 Mar 2022</span>
                                                <span className="cs-post_meta_item">25 Comments</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cs-height_30 cs-height_lg_30" />
                        </div>
                        {/* .col */}
                        <div className="col-lg-4">
                            <div className="cs-post cs-style1">
                                <a href="blog-details.html" className="cs-post_thumb">
                                    <div
                                        className="cs-post_thumb_in cs-bg"
                                        data-src="assets/img/general/general_22.jpeg"
                                        style={{backgroundImage:'url(assets/img/general/general_22.jpeg)'}}
                                    />
                                </a>
                                <div className="cs-post_info">
                                    <h2 className="cs-post_title">
                                        <a href="blog-details.html">Why do NFTs even make sense?</a>
                                    </h2>
                                    <div className="cs-post_subtitle">
                                        It is a long established fact that a reader will be distrac by
                                        the readable content of a page...
                                    </div>
                                    <div className="cs-height_20 cs-height_lg_20" />
                                    <div className="cs-post_avatar">
                                        <a href="#" className="cs-post_avatar_img">
                                            <img src="assets/img/avatar/avatar_19.png" alt="Avatr" />
                                        </a>
                                        <div className="cs-post_avatar_right">
                                            <h2 className="cs-post_avatar_name cs-semi_bold">
                                                <a href="#">Author</a>
                                            </h2>
                                            <div className="cs-post_meta">
                                                <span className="cs-post_meta_item">5 Mar 2022</span>
                                                <span className="cs-post_meta_item">25 Comments</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cs-height_30 cs-height_lg_30" />
                        </div>
                        {/* .col */}
                        <div className="col-lg-4">
                            <div className="cs-post cs-style1">
                                <a href="blog-details.html" className="cs-post_thumb">
                                    <div
                                        className="cs-post_thumb_in cs-bg"
                                        data-src="assets/img/general/general_23.jpeg"
                                        style={{backgroundImage:'url(assets/img/general/general_23.jpeg)'}}
                                    />
                                </a>
                                <div className="cs-post_info">
                                    <h2 className="cs-post_title">
                                        <a href="blog-details.html">Why do NFTs even make sense?</a>
                                    </h2>
                                    <div className="cs-post_subtitle">
                                        It is a long established fact that a reader will be distrac by
                                        the readable content of a page...
                                    </div>
                                    <div className="cs-height_20 cs-height_lg_20" />
                                    <div className="cs-post_avatar">
                                        <a href="#" className="cs-post_avatar_img">
                                            <img src="assets/img/avatar/avatar_19.png" alt="Avatr" />
                                        </a>
                                        <div className="cs-post_avatar_right">
                                            <h2 className="cs-post_avatar_name cs-semi_bold">
                                                <a href="#">Author</a>
                                            </h2>
                                            <div className="cs-post_meta">
                                                <span className="cs-post_meta_item">5 Mar 2022</span>
                                                <span className="cs-post_meta_item">25 Comments</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cs-height_30 cs-height_lg_30" />
                        </div>
                        {/* .col */}
                    </div>
                </div>
            </section>
            {/* Start Blog Section */}
        </>

    )
}

export default Blog