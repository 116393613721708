import React from 'react'

const TopStreamerEmotes = () => {
    return (
        <section>
            <div className="container">
                <div className="cs-section_heading cs-style2">
                    <div className="cs-section_left">
                        <h2 className="cs-section_title">Top Streamers </h2>
                    </div>
                    <div className="cs-section_right">
                        <div className="cs-toggle_box cs-section_toggle">
                            <div className="cs-toggle_btn">
                                Recent Active{" "}
                                <i className="cs-toggle_arrow fas fa-caret-down fa-fw" />
                            </div>
                            <div className="cs-toggle_body cs-box_shadow">
                                <a href="">Last 7 days</a>
                                <a href="">Last 30 days</a>
                                <a href="">Last 90 days</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cs-height_45 cs-height_lg_45" />
                <div className="cs-grid_5 cs-gap_30">
                    <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
                        <a href="#" className="cs-card_thumb cs-zoom_effect">
                            <img
                                src="assets/img/general/general_7.jpeg"
                                alt="Image"
                                className="cs-zoom_item"
                            />
                        </a>
                        <a href="#" className="cs-avatar">
                            <img src="assets/img/avatar/avatar_7.png" alt="Avatar" />
                            <span className="cs-avatar_status" />
                        </a>
                        <div className="cs-card_info">
                            <h3 className="cs-card_title">
                                <a href="#">Audioglyphs</a>
                            </h3>
                            <div className="cs-card_subtitle">
                                <i className="far fa-check-circle fa-fw" />
                                <span>Guarranted Authentic</span>
                            </div>
                            <div className="cs-catd_meta">
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="fas fa-list-ul fa-fw" />
                                    <span>120 Items</span>
                                </a>
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="far fa-eye fa-fw" />
                                    <span>1K Views</span>
                                </a>
                            </div>
                            <div className="cs-catd_footer">
                                <div className="cs-left">
                                    <h4>Volume</h4>
                                    <p>22.9k</p>
                                </div>
                                <div className="cs-left">
                                    <h4>Floor Price</h4>
                                    <p>4.2 ETH</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
                        <a href="#" className="cs-card_thumb cs-zoom_effect">
                            <img
                                src="assets/img/general/general_8.jpeg"
                                alt="Image"
                                className="cs-zoom_item"
                            />
                        </a>
                        <a href="#" className="cs-avatar">
                            <img src="assets/img/avatar/avatar_8.png" alt="Avatar" />
                        </a>
                        <div className="cs-card_info">
                            <h3 className="cs-card_title">
                                <a href="#">Audioglyphs</a>
                            </h3>
                            <div className="cs-card_subtitle">
                                <i className="far fa-check-circle fa-fw" />
                                <span>Guarranted Authentic</span>
                            </div>
                            <div className="cs-catd_meta">
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="fas fa-list-ul fa-fw" />
                                    <span>234 Items</span>
                                </a>
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="far fa-eye fa-fw" />
                                    <span>2.1K Views</span>
                                </a>
                            </div>
                            <div className="cs-catd_footer">
                                <div className="cs-left">
                                    <h4>Volume</h4>
                                    <p>87.9k</p>
                                </div>
                                <div className="cs-left">
                                    <h4>Floor Price</h4>
                                    <p>6.7 ETH</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
                        <a href="#" className="cs-card_thumb cs-zoom_effect">
                            <img
                                src="assets/img/general/general_9.jpeg"
                                alt="Image"
                                className="cs-zoom_item"
                            />
                        </a>
                        <a href="#" className="cs-avatar">
                            <img src="assets/img/avatar/avatar_9.png" alt="Avatar" />
                            <span className="cs-avatar_status" />
                        </a>
                        <div className="cs-card_info">
                            <h3 className="cs-card_title">
                                <a href="#">Audioglyphs</a>
                            </h3>
                            <div className="cs-card_subtitle">
                                <i className="far fa-check-circle fa-fw" />
                                <span>Guarranted Authentic</span>
                            </div>
                            <div className="cs-catd_meta">
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="fas fa-list-ul fa-fw" />
                                    <span>560 Items</span>
                                </a>
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="far fa-eye fa-fw" />
                                    <span>3.1K Views</span>
                                </a>
                            </div>
                            <div className="cs-catd_footer">
                                <div className="cs-left">
                                    <h4>Volume</h4>
                                    <p>99.9k</p>
                                </div>
                                <div className="cs-left">
                                    <h4>Floor Price</h4>
                                    <p>4.8 ETH</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
                        <a href="#" className="cs-card_thumb cs-zoom_effect">
                            <img
                                src="assets/img/general/general_10.jpeg"
                                alt="Image"
                                className="cs-zoom_item"
                            />
                        </a>
                        <a href="#" className="cs-avatar">
                            <img src="assets/img/avatar/avatar_10.png" alt="Avatar" />
                        </a>
                        <div className="cs-card_info">
                            <h3 className="cs-card_title">
                                <a href="#">Audioglyphs</a>
                            </h3>
                            <div className="cs-card_subtitle">
                                <i className="far fa-check-circle fa-fw" />
                                <span>Guarranted Authentic</span>
                            </div>
                            <div className="cs-catd_meta">
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="fas fa-list-ul fa-fw" />
                                    <span>345 Items</span>
                                </a>
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="far fa-eye fa-fw" />
                                    <span>2.1K Views</span>
                                </a>
                            </div>
                            <div className="cs-catd_footer">
                                <div className="cs-left">
                                    <h4>Volume</h4>
                                    <p>19.8k</p>
                                </div>
                                <div className="cs-left">
                                    <h4>Floor Price</h4>
                                    <p>3.5 ETH</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
                        <a href="#" className="cs-card_thumb cs-zoom_effect">
                            <img
                                src="assets/img/general/general_11.jpeg"
                                alt="Image"
                                className="cs-zoom_item"
                            />
                        </a>
                        <a href="#" className="cs-avatar">
                            <img src="assets/img/avatar/avatar_11.png" alt="Avatar" />
                        </a>
                        <div className="cs-card_info">
                            <h3 className="cs-card_title">
                                <a href="#">Audioglyphs</a>
                            </h3>
                            <div className="cs-card_subtitle">
                                <i className="far fa-check-circle fa-fw" />
                                <span>Guarranted Authentic</span>
                            </div>
                            <div className="cs-catd_meta">
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="fas fa-list-ul fa-fw" />
                                    <span>140 Items</span>
                                </a>
                                <a href="#" className="cs-catd_meta_item">
                                    <i className="far fa-eye fa-fw" />
                                    <span>2.1K Views</span>
                                </a>
                            </div>
                            <div className="cs-catd_footer">
                                <div className="cs-left">
                                    <h4>Volume</h4>
                                    <p>12.9k</p>
                                </div>
                                <div className="cs-left">
                                    <h4>Floor Price</h4>
                                    <p>2.8 ETH</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default TopStreamerEmotes