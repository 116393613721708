import { createContext, useContext, useEffect, useState } from "react";
import { MainContextType } from "./@types/MainContextType";
import { BaseApi } from "../api/BaseApi";




const MainContext = createContext<MainContextType | null>(null);



const MainContextProvider = ({ children }: any) => {
    const [allHeaderCategory, setAllHeaderCategory] = useState<Array<any> | null>(null);


    useEffect(() => {
        initApp();
    }, []);


    const initApp = () => {
        getAllHeaderCategory();
    }

    const getAllHeaderCategory = async () => {
        try {
            const response = await BaseApi.get("/category/webCategory");
            console.log(response.data);
            if (response.status == 200) {
                setAllHeaderCategory(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <MainContext.Provider
            value={{
                allHeaderCategory,
                setAllHeaderCategory
            }}
        >
            {children}
        </MainContext.Provider>
    );

}
const useMainContext = () => useContext(MainContext);


export {
    useMainContext,
    MainContextProvider
}