
const sliderData = [
    {
        id: 1,
        title: "",
        description: "",
        image: require("../assets/img/banner0.png")
    },
    {
        id: 2,
        title: "The Best Beneficial Place \nto Collect, Buy and Sell \nGamers Marketplace",
        description: "Secure and secret nGamers for gaming, entertainment, metaverse and data  management time capsules.",
        image: require("../assets/img/hero_bg2.jpeg")
    },
    {
        id: 3,
        title: "The Best Beneficial Place \nto Collect, Buy and Sell \nGamers Marketplace",
        description: "Secure and secret nGamers for gaming, entertainment, metaverse and data  management time capsules.",
        image: require("../assets/img/hero_bg3.jpeg")
    }
];



const Slider = () => {
    return (
        <>
            <section className="container-fluid" style={{ marginTop: '4rem' }}>
                <div className="cs-hero_slider_1">
                    <div className="cs-slider cs-style1">
                        <div
                            className="cs-slider_container"
                            data-autoplay={0}
                            data-loop={1}
                            data-speed={600}
                            data-center={0}
                            data-slides-per-view={1}
                        >
                            <div className="cs-slider_wrapper">
                                {sliderData.map((d, i) => {
                                    return (
                                        <div className="cs-slide" key={i}>
                                            <div
                                                className="cs-hero cs-style1 cs-bg cs-center"
                                                data-src={d.image}
                                            >
                                                <div className="container">
                                                    <div className="cs-hero_text">
                                                        <h1 className="cs-hero_title">
                                                            {d.title}
                                                        </h1>
                                                        <div className="cs-hero_subtitle cs-medium">
                                                            {d.description}
                                                        </div>
                                                        {/* <div className="cs-hero_btns">
                                                            <a href="#" className="cs-hero_btn cs-style1 cs-color1">
                                                                <span>Explore</span>
                                                            </a>
                                                            <a href="#" className="cs-hero_btn cs-style1 cs-color2">
                                                                <span>Create</span>
                                                            </a>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {/* .cs-slider_container */}
                        <div className="cs-slider_arrows cs-style2">
                            <div className="cs-left_arrow cs-center cs-box_shadow" style={{ backgroundColor: "#ccc" }}>
                                <svg
                                    width={14}
                                    height={15}
                                    viewBox="0 0 14 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.0269 7.55957H0.817552"
                                        stroke="#fff"
                                        strokeWidth="1.16474"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M6.92188 1.45508L0.817222 7.55973L6.92188 13.6644"
                                        stroke="#fff"
                                        strokeWidth="1.16474"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="cs-right_arrow cs-center cs-box_shadow" style={{ backgroundColor: "#ccc" }}>
                                <svg
                                    width={14}
                                    height={15}
                                    viewBox="0 0 14 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.816895 7.55957H13.0262"
                                        stroke="#fff"
                                        strokeWidth="1.16474"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M6.92188 1.45508L13.0265 7.55973L6.92188 13.6644"
                                        stroke="#fff"
                                        strokeWidth="1.16474"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="cs-pagination cs-style1 cs-hidden" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Slider