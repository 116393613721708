import React from 'react'


interface EmotesSliderCardsType {
    title: string,

}

const EmotesSliderCards = ({ title }: EmotesSliderCardsType) => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="cs-section_heading cs-style2 mb-4">
                            <div className="cs-section_left">
                                <h2 className="cs-section_title">{title}</h2>
                            </div>
                            <div className="cs-section_right">
                                <div className="cs-toggle_box cs-section_toggle">
                                    <div className="cs-toggle_btn">
                                        Recent Active{" "}
                                        <i className="cs-toggle_arrow fas fa-caret-down fa-fw" />
                                    </div>
                                    <div className="cs-toggle_body cs-box_shadow">
                                        <a href="">Last 7 days</a>
                                        <a href="">Last 30 days</a>
                                        <a href="">Last 90 days</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cs-height_0 cs-height_lg_40" />

                        <div className="cs-general_box_1">
                            <div className="cs-slider cs-style1 cs-gap-20">
                                <div
                                    className="cs-slider_container"
                                    data-autoplay={0}
                                    data-loop={1}
                                    data-speed={600}
                                    data-center={0}
                                    data-slides-per-view="responsive"
                                    data-xs-slides={2}
                                    data-sm-slides={3}
                                    data-md-slides={3}
                                    data-lg-slides={3}
                                    data-add-slides={3}
                                >
                                    <div className="cs-slider_wrapper  slick-initialized slick-slider slick-dotted">
                                        <div className="slick-list draggable">
                                            <div
                                                className="slick-track"
                                                style={{
                                                    opacity: 1,
                                                    width: 3542,
                                                    // transform: "translate3d(-966px, 0px, 0px)"
                                                }}
                                            >

                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v: any, i: number) => {
                                                    return (<div
                                                        key={i}
                                                        className="slick-slide slick-cloned"
                                                        data-slick-index={-3}
                                                        id=""
                                                        aria-hidden="true"
                                                        style={{ width: 322 }}
                                                    >
                                                        <div>
                                                            <div
                                                                className="cs-slide"
                                                                style={{ width: "100%", display: "inline-block" }}
                                                            >
                                                                <div className="cs-card cs-style2 cs-box_shadow cs-white_bg">
                                                                    <a href="#" className="cs-card_thumb cs-zoom_effect">
                                                                        <img
                                                                            src="assets/img/general/general_2.jpeg"
                                                                            alt="Image"
                                                                            className="cs-zoom_item"
                                                                        />
                                                                    </a>
                                                                    <div className="cs-card_info  pt-3">
                                                                        <h3 className="cs-card_title">
                                                                            <a href="#">Black White Stream Overlay Template Package</a>
                                                                        </h3>
                                                                        <div className="cs-card_subtitle">
                                                                            @Stream Overlay Template Package
                                                                        </div>
                                                                        <a href="#" className="cs-card_btn">
                                                                            Buy Now
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>);
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cs-pagination cs-style1">
                                        <ul className="slick-dots" role="tablist" style={{}}>
                                            <li className="slick-active" role="presentation">
                                                <button
                                                    type="button"
                                                    role="tab"
                                                    id="slick-slide-control10"
                                                    aria-controls="slick-slide10"
                                                    aria-label="1 of 2"
                                                    tabIndex={0}
                                                    aria-selected="true"
                                                >
                                                    1
                                                </button>
                                            </li>
                                            <li role="presentation">
                                                <button
                                                    type="button"
                                                    role="tab"
                                                    id="slick-slide-control11"
                                                    aria-controls="slick-slide11"
                                                    aria-label="2 of 2"
                                                    tabIndex={-1}
                                                >
                                                    2
                                                </button>
                                            </li>
                                            <li role="presentation">
                                                <button
                                                    type="button"
                                                    role="tab"
                                                    id="slick-slide-control12"
                                                    aria-controls="slick-slide12"
                                                    aria-label="3 of 2"
                                                    tabIndex={-1}
                                                >
                                                    3
                                                </button>
                                            </li>
                                            <li role="presentation">
                                                <button
                                                    type="button"
                                                    role="tab"
                                                    id="slick-slide-control13"
                                                    aria-controls="slick-slide13"
                                                    aria-label="4 of 2"
                                                    tabIndex={-1}
                                                >
                                                    4
                                                </button>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >

    )
}

export default EmotesSliderCards;